  import React, {useState, useEffect} from "react";
  import './home.css';
  import Background from "./backgroundStore.jpeg";
  <link rel="stylesheet" href="https://use.typekit.net/hcf3xvk.css"></link>

  const Home = () => {
    const text = "Get the 2024 Official Jersey";
    const handleElectricityClick = async (e) => {
      e.preventDefault(); // Prevent default link behavior
      
      try {
        const response = await fetch('http://localhost:4000/paymentRoutes/fetchElectricityPrices', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        const data = await response.json();
        console.log('Electricity prices fetched:', data);
        
        // You can handle the response data here
        // For example, show a success message or display the data
        alert('Electricity prices fetched successfully!');
        
      } catch (error) {
        console.error('Error fetching electricity prices:', error);
        alert('Failed to fetch electricity prices');
      }
    };


    return (
    
      <div style={{marginTop: '20px'}}>
      <a className="home-store-container" href="/store" style={{ backgroundImage: `url(${Background})` }}>
        <h1 className="home-store-h1 neon-text">
          {text.split(' ').map((word, wordIndex) => (
            <span key={wordIndex} className="neon-word">
              {word.split('').map((char, charIndex) => (
                <span key={charIndex} className="neon-letter">{char}</span>
              ))}
            </span>
          ))}
        </h1>
      </a>

        <div className="scheduled-matches">
          <h2 className="upcoming-matches-h2">Our Upcoming Matches</h2>
          <div className="matches-holder">
              <a className="match" href="https://www.twitch.tv/cashoutcavaliers" target="_blank" rel="noopener noreferrer">
                  <div className="match-specs-holder">
                      <p>GGligaen CS2</p>
                      <p>BO3</p>
                  </div>
                  <div className="match-line"></div>
                  <p className="match-date">15/09/2024 19:00</p>
                  <p className="vs">vs</p>
                  <h3 className="match-opponent"> Slaughtergang</h3>
                  <a className="match-link">Tune in</a>
                  
                  
              </a>
              <a className="match" href="https://www.twitch.tv/cashoutcavaliers" target="_blank" rel="noopener noreferrer">
                  <div className="match-specs-holder">
                      <p>GGligaen CS2</p>
                      <p>BO3</p>
                  </div>
                  <div className="match-line"></div>
                  <p className="match-date">17/09/2024 19:00</p>
                  <p className="vs">vs</p>
                  <h3 className="match-opponent"> Void</h3>
                  <a  className="match-link">Tune in</a>
                  
                  
              </a>
              <a className="match"            href="#" onClick={handleElectricityClick} target="_blank" rel="noopener noreferrer">
                  <div className="match-specs-holder">
                      <p>GGligaen CS2</p>
                      <p>BO3</p>
                  </div>
                  <div className="match-line"></div>
                  <p className="match-date">24/09/2024 19:00</p>
                  <p className="vs">vs</p>
                  <h3 className="match-opponent"> Rolige Karar CSGO</h3>
                  <a  className="match-link">Tune in</a>
                  
                  
              </a>
          </div>

        </div>
        <div className="footer">
        
        </div>
      </div>
    );
  }

  export default Home;